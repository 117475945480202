import React, { FC } from 'react';
import styles from './Intro.module.css';
import ProfilePic from '../ProfilePic/ProfilePic';
import Updates from '../Updates/Updates';
import {Row, Col} from 'react-bootstrap';
import profilePic from '../../img/profile_pic.jpg';

interface BadgeLink {img: string, link: string};
interface IntroProps {name: string, intro: string, badgeLinks: Record<string, BadgeLink>};

const Intro: FC<IntroProps> = (prop) => {
  const {name: profileName, intro: message, badgeLinks} = prop;
  return (
  <div className={[styles.Intro, "mx-auto"].join(" ")}>
    <Row className={[styles.center, "mx-auto"].join(" ")}>
      <Col md={12} lg={5} className={styles.left}>
        <ProfilePic picUrl={profilePic}/>
        <h3>{profileName}</h3>
        <section className={styles.badges}>
          { Object.entries(badgeLinks).map(([key, val], index) => (<a href={val.link} key={index}><img src={val.img} alt={`${key} badge`} /></a>) )} 
        </section>
        <small>
          Last Update: 04/15/2024
        </small> 
      </Col>
      <Col className={styles.introduction} md={12} lg={5}>
        {message}
      </Col>
    </Row>
    <hr/>
    {/* <Row className="mx-auto">
      <Col>
        <Updates />
      </Col>
    </Row> */}
  </div>
)};

export default Intro; 
export type {IntroProps, BadgeLink};
