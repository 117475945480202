import { createProject } from "./maintainingApps";

const myProjects = [
    createProject(
        "Novelty Idea Detection",
        ["NLP", "Learning Analytics"],
        "Develop a framework that combines embeddings from Large Language Models with semantic role labeling to offer explainable and actionable topic discovery, facilitating discourse analysis.",
        undefined,
        undefined
    ),
    createProject(
        "AdaRel",
        ["Python3", "machine learning", "Data Analysis"],
        "AdaRel is an adaptive software reliability prediction framework. My contribution to this project is in time series forecasting, anomaly detection and experiment designing.",
        undefined,
        [
            "Design framework architecture and implement it in Python", 
            "Design experiment and conduct four case studies on real-life data set", 
            "Organize, analyze Data", 
        ]
    )
]

export default myProjects;