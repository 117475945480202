import React, {useEffect} from 'react';
import './App.css';
import {Row, Col, Container} from 'react-bootstrap';

import Intro from '../components/Intro/Intro';
import ProjectCard from '../components/ProjectCard/ProjectCard';
import MyNavbar from '../components/MyNavbar/MyNavbar';
import MyFooter from '../components/MyFooter/MyFooter';

// static infos
import Me from '../static/intro';
import maintainingApps from '../static/maintainingApps';
import myProjects from '../static/myProjects';

function App() {
  useEffect(()=> {
    document.title = "Chun Yen Chang-Sundin";
  });
  return (
    <div className="App">
      <MyNavbar />
      <main className="main-frame">
        <Container>
          <Intro {...Me}/>
          <Row>
            <h4>Maintainer of</h4>
            {maintainingApps.map((item, index)=> 
              <Col md={12} lg={6} key={index}>
                <ProjectCard {...item}/>
              </Col> 
            )}
          </Row>
          <Row>
            <h4>Projects</h4>
            {myProjects.map((item, index)=> 
              <Col md={12} lg={6} key={index}>
                <ProjectCard {...item}/>
              </Col> 
            )}
          </Row>
        </Container>
      </main>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <MyFooter />
    </div>
  );
}

export default App;
