import React, { FC } from 'react';
import styles from './MyNavbar.module.css';
import { Link } from 'react-router-dom';
import {Nav, Navbar, Container} from 'react-bootstrap';


interface NavbarProps {}

const MyNavbar: FC<NavbarProps> = () => {
  const items = [
    {name:"Experience", href: '/experience', disabled: false},
    // {name: "Posts", href: '/', disabled: true}, 
    {name: "Hobbies", href: '/hobbies', disabled: false}
  ];

  return (
    <Navbar className={styles.MyNavbar} expand="lg">
      <Container>
        <Navbar.Brand href="/">Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="my-navbar"></Navbar.Toggle>
        <Navbar.Collapse id="my-navbar">
          <Nav>
            {/* {items.map((item, index) => <Nav.Link key={index} href={item.href} disabled={item.disabled}>{item.name}</Nav.Link>)} */}
            {items.map((item, index) => <Link className='nav-link' key={index} aria-disabled={item.disabled} to={item.href}>{item.name}</Link>)}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
)};

export default MyNavbar;
