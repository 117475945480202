import React, { FC } from 'react';
import styles from './MyFooter.module.css';

interface MyFooterProps {}

const MyFooter: FC<MyFooterProps> = () => (
  <div className={styles.MyFooter}>
    © 2024 Chun Yen Chang-Sundin.
  </div>
);

export default MyFooter;
