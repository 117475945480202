function createProject(pname: string, stacks: string[], pdes?: string, resp?: string, respList?: string[]) {
    return {
      projectName: pname,
      stacks: stacks,
      projectDes: pdes,
      respDes: resp,
      respList: respList
    }
}

const maintainingApps = [
  createProject(
    "Knowledge Forum 6",
    ["AngularJS", "MongoDB", "Express"],
    "Knowledge Forum is a digital group workspace designed to support the process of knowledge building.",
    "Maintaining the web application on development side",
    [
      "implement new learning anyltics features upon clients' request",
      "collect feedback and improve the platform's user experience and performance",
      "identify issues/bugs and address them"
    ]
  ),
  createProject(
    "KBDAC", 
    ["NLTK", "Flask", "Express + ejs"],
    "A web application for learning analytics for teachers and students.",
    undefined,
    [
      "Maintain the websites and clean up inconsistent legacy code.", 
      "Design API between services",
      "Optimize run-time performance by profiling the application and improve user experience.", 
      "integrate old features and redesign user interface.", 
      "Implement new features according to customers needs.", 
    ]
  )
]

export default maintainingApps;
export {createProject, maintainingApps};