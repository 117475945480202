import React, {useEffect} from 'react';
import './App.css';
import {Row, Container} from 'react-bootstrap';


import MyNavbar from '../components/MyNavbar/MyNavbar';
import MyFooter from '../components/MyFooter/MyFooter';

// static infos


function Experience() {
    useEffect(()=> {
        document.title = "Chun Yen Chang-Sundin";
      });
      return (
        <div className="App">
            <MyNavbar />
            <main className="main-frame">
                <Container>
                    <h3>Experience</h3>
                    <Row>
                        <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent:'space-between'}}>
                            <h4 className='exp-title'>Research Assistant</h4>
                            <div style={{color: 'gray'}}>Dec 2021 - present</div>
                        </div>
                        
                        <div>
                        Contributed to two research projects, involving designing experiments, conducting ETL (Extract, Transform, Load) processes on data, applying various machine learning algorithms, and analyzing the results.
                        </div>
                        
                        <h5>Research Projects:</h5>
                        <div style={{paddingLeft: '2rem'}}>
                            <strong>Discourse Analysis Framework</strong>
                            <div>
                            Develop a framework that combines embeddings from Large Language Models with semantic role labeling to offer explainable and actionable topic discovery, facilitating discourse analysis.
                            </div>

                            <strong>Adaptive Reliability Prediction Framework (AdaRel)</strong>
                            <div>
                            Developed an adaptive software reliability prediction framework with a specialization in time series forecasting and anomaly detection
                                <ul>
                                    <li>Spearheaded the architectural design of the Python framework in its initial phase.</li>
                                    <li>Cataloged experiments and documented codebase.</li>
                                    <li>Transform, load the data; organize, visualize, and analyze results.</li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent:'space-between'}}>
                            <h4 className='exp-title'>Web Developer</h4>
                            <div style={{color: 'gray'}}>Jan 2020 - present</div>
                        </div>
                        <div>
                        Collaborate with team to deploy new features and collect requirements via communicating with clients. Responsible for the ETL process, and ensuring clean and clear visualization in the front-end.
                        </div>
                        <h5>Projects: </h5>
                        <div style={{paddingLeft: '2rem'}}>
                            <strong>Knowledge Forum 6</strong>
                            <div>
                            As one of the leading developers, actively engage in discussions with clients regarding new learning analytic solutions and with colleagues regarding challenges in implementation.
                                <ul>
                                    <li>Developed 5 key features, ranging from crafting queries and processing data from MongoDB to creating UI/UX designs for new learning analytic functionalities for over 200 users. </li>
                                    <li>Introduced load balancing mechanics to support 120 concurrent users accessing the same board.</li>
                                    <li>Design efficient RESTful API endpoints, achieving up to 20% reduction in data transmitted and 50% improvement of response time.</li>
                                    <li>Update and restructure legacy code to support new features and revamp the user interface to conform to modern layouts.</li>
                                    <li>Mentored 5 student assistants who were new to the project and conducted code reviews.</li>
                                </ul>
                            </div>
                            <strong>Knowledge Building Data Analytic Center (KBDAC)</strong>
                            <div>
                                A nodeJS web application with Embedded JavaScript for teachers and students for learning analytics and a companion tool to Knowledge Forum.
                                <ul>
                                    <li>Clean up inconsistent legacy code.</li>
                                    <li>Optimize run-time performance by analyzing and profiling the code and by introducing caching.</li>
                                    <li>Refactor existing features and revamp user interface.</li>
                                    <li>Implemented 4 major new features in the application, benefiting over 60 users.</li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent:'space-between'}}>
                            <h4 className='exp-title'>Teaching Assistant</h4>
                            <div style={{color: 'gray'}}>Aug 2020 - Dec 2021</div>
                        </div>
                        <div>
                            <ul>
                                <li>Software Engineering, (graduate level &#38; 400 level)</li>
                                <li>Algorithms and Data Structure, (graduate level &#38; 200 level)</li>
                                <li>Introduction to Computer Science (100 level)</li>
                            </ul>
                        </div>
                    </Row>

                </Container>
            </main>
            <MyFooter />
        </div>
      );
}

export default Experience;