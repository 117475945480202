import React, {useEffect} from 'react';
import './App.css';
import {Row, Col, Container} from 'react-bootstrap';

import MyNavbar from '../components/MyNavbar/MyNavbar';
import MyFooter from '../components/MyFooter/MyFooter';

import {Underwater, Rhea, Teahouse, SkyInKurume} from '../img/photos';


function Hobbies() {
    useEffect(()=> {
        document.title = "Chun Yen Chang-Sundin";
      });
      return (
        <div className="App">
            <MyNavbar />
            <main className="main-frame" style={{paddingBottom: '2rem'}}>
                <Container>
                    <h4>Digital Drawing</h4>
                    <div style={{display: 'flex', flexFlow: 'row nowrap', gap: '1rem'}}>
                        <div style={{display: 'flex', flexFlow: 'column nowrap', gap: '1rem', flex: '1 0 0'}}>
                            <img src={ Underwater } style={{objectFit: 'cover', width: '100%', borderRadius: '1rem'}} title="underwater"/>
                            <img src={ SkyInKurume } style={{objectFit: 'cover', width: '100%', borderRadius: '1rem'}} title="sky in Kurume" />
                        </div>
                        <div style={{display: 'flex', flexFlow: 'column nowrap', gap: '1rem', flex: '.83 0 0'}}>
                            <img src={ Rhea } style={{objectFit: 'cover', width: '100%', borderRadius: '1rem'}} title="yaoyue tea house" />
                            <img src={ Teahouse } style={{objectFit: 'cover', width: '100%', borderRadius: '1rem'}} title="yaoyue tea house" />
                        </div>
                    </div>
                </Container>
            </main>
            <MyFooter />
        </div>
      );
}

export default Hobbies;