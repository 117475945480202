import React from 'react';
import  ReactDOM  from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './pages/App';
import ErrorPage from './pages/error-page';
import Experience from './pages/Experience';
import Hobbies from './pages/Hobbies';

const router = createHashRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/experience",
    element: <Experience />,
  },
  {
    path: '/hobbies',
    element: <Hobbies />,
  }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <RouterProvider router={router} /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
