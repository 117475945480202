import React, { FC } from 'react';
import styles from './ProjectCard.module.css';

interface ProjectCardProps {projectName: string, stacks: string[], projectDes?: string, respDes?: string, respList?: string[] }

const ProjectCard: FC<ProjectCardProps> = (prop) => {
  const {projectName, stacks, projectDes, respDes, respList, } = prop;
  const pDesDisplay = projectDes ?? 'Description Coming Soon';

  return (
    <div className={styles.ProjectCard}>
      <div className="p-3">
        <h5>{projectName}</h5>
        <p className={styles.TextDescription}>{pDesDisplay}</p>
        { (respDes || respList ) && ( <strong>Responsibilities<br /></strong> )}
        { respDes }
        { respList && ( <ul> {respList.map((item, index)=> (<li key={index}>{item}</li>))} </ul> )}

        <section className={styles.ToolTags}>
          {stacks.map((item, index) => <div key={index}>{item}</div>)}
        </section>
      </div>
    </div>
  )
};

export default ProjectCard;
export type {ProjectCardProps};
