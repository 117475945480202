import React, { FC } from 'react';
import styles from './ProfilePic.module.css';
import profilePic from '../../img/profile_pic.jpg';

interface ProfilePicProps {
  picUrl: string;
}

const ProfilePic: FC<ProfilePicProps> = ({picUrl}) => (
  <div className={["mx-auto", styles.profileFrame].join(" ")}>
      <div className={[styles.profilePic, "mx-auto"].join(" ")}>
          <img alt="profile picture" src={picUrl} className={["mx-auto",styles.imgFluid].join(" ")} />
      </div>
      <div className={[styles.blob, styles.blobTeal].join(" ")}></div>
      <div className={[styles.blob, styles.blobBlue].join(" ")}></div>
  </div>
);

export default ProfilePic;
