const Me = {
    name: "Chun Yen Chang-Sundin",
    intro: 'Hello, I am a PhD candidate in Computer Science at University at Albany under the supervision of Dr. Mei-Hwa Chen. I am a researcher who is interested in NLP, learning analytics and application of knowledge graphs. I am also a web developper of two applications.',
    badgeLinks: {
        github: { 
            img: "https://img.shields.io/badge/jerrisonchang-000000?style=flat&logo=github&logoColor=white", 
            link: 'https://github.com/JerrisonChang'},
        linkedin: {
            img: 'https://img.shields.io/badge/chunyen--cs-0077B5?style=flat&logo=linkedin&logoColor=white', 
            link: 'https://www.linkedin.com/in/chunyen-cs/'}
    }
}

export default Me;